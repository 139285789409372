<template>
  <div id="component-list-category">
    {{/* CARD PARENT CAT */}}
    <div class="card card-border">
      <div class="card-header p-0 bg-white">
        <div class="ml-3 mt-3 mb-3">
          <h5>Choisir une catégorie :</h5>
        </div>
      </div>
      <div class="card-body">
        <div v-for="(parent, index) in CategoryModule.parentCategories" :key="index">
          <div
              class="d-flex p-2 c-pointer parent-cat"
              :class="(selectCat && selectCat.id === parent.id) ? 'cat-active' : ''"
              v-on:click="emitSelectCat(parent)"
          >
            <b-icon
                icon="folder2"
                font-scale="1.5"
                variant="info"
                class="icon-folder mr-2"
            ></b-icon>
            {{ parent.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    selectCat: Object,
  },
  data() {
    return {
      isValidate: false
    }
  },
  created() {
    this.$store.dispatch('fetchParentCategories')
  },
  methods: {
    emitSelectCat(parentCat) {
      this.$emit('emit-select-cat', parentCat)
    },
  },
  computed: {
    ...mapState(['CategoryModule']),
  },
}
</script>

<style scoped>

</style>
